<template>
    <div>
        <div class="col-md-12 mb-20">
            <div class="input-group form-group AFTER">
                <span class="input-group-addon"><i class='fa fa-envelope'></i></span>
                    <input class="form-control" data-val="true" data-val-required="UserName!" id="UNAME" name="UNAME" placeholder="Enter E-Mail Address" type="text"  v-model="email" />
                <span style="color:red"><span class="field-validation-valid" data-valmsg-for="UNAME" data-valmsg-replace="true"></span></span>
            </div>
            <div class="input-group form-group AFTER">
                <span class="input-group-addon"><i class='fa fa-key'></i></span>
                    <input class="form-control" data-val="true" data-val-required="Password!" id="PASS" v-model="password" name="PASS" placeholder="Enter Password" type="password" />
                <span style="color:red"><span class="field-validation-valid" data-valmsg-for="PASS" data-valmsg-replace="true"></span></span>
            </div>
            
            <div class="form-group">
                <button type="button" @click="loginwithemailpassword()" class="btn  btn-block  btn-submit pull-right BTNSUB"> Login  </button>
            </div>
            <div class="extr_textbox5 t1-left" v-if="false">
                <a href="#" @click="hidescreen()">Forget Password ?</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
      return{
        email:'',
        password:'',
      }
    },
    mounted(){
        localStorage.setItem("countstore", 0);
        console.log(localStorage.getItem("countstore"));
    },
    methods:{
        loginwithemailpassword(){
            this.$store.commit('assignloadingstatus',1)
    		let param = {email:this.email,password:this.password};
    		this.$http.post('api/loginwithemailpassword',param)
				.then(response=>this.$parent.processResponse(response.data))
				.catch(error=>console.log(error));
    	},
        
    }
}
</script>