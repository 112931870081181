<template>
  	<div class="col-md-9">
  		
  			<div class="col-md-12">
           		<img alt="" src="images/logo.png" style="width: 14%;">
        	</div>
  			<div class="login-box" v-show="loginpop==1">
				<h1><i class="fa fa-bookmark"></i>&nbsp;Welcome </h1>
        		<hr>
        		<h5>VSDIGI Login</h5>
                    <div class="input-box">
                    	<div class="row">
                        	<div class="col-md-12">
							    <phone-otp-login v-if="logintype==1"></phone-otp-login>
							    <phone-password-login v-if="logintype==2"></phone-password-login>
								<forgot-password v-if="logintype==5"></forgot-password>
							    <email-password-login v-if="logintype==3"></email-password-login>
                                <div v-if="displayError">{{ message }}</div>
							    <div class="col-12" v-if="false">
							    	<div class="row">
								    	<div class="col-md-12 mt-20">
									    	<button @click="changelogintype(1)"  type="button"  v-if="logintype!=1" class="btn btn-block btn-outline-warning extr_textbox4">
									    	<i class="fa fa-mobile"></i>
									    	
									    	Login with Mobile and OTP</button>
									    </div>
								    	
									    <div class="col-md-12 mt-20">
									    	<button @click="changelogintype(2)"  type="button" v-if="logintype!=2" class="btn btn-block btn-outline-info extr_textbox4">
									    	<i class="fa fa-mobile"></i>
									    	Login with Mobile and Password</button>
									    </div>
								    	
								    	<div class="col-md-12 mt-20">
								    		<button @click="changelogintype(3)"  type="button" v-if="logintype!=3" class="btn btn-block btn-outline-primary extr_textbox4">
								    		<i class="fa fa-envelope"></i>
								    		Login with Email and Password</button>
								    	</div>
								    	<div class="col-md-12 mt-20">
									    	<a href="#" @click="changelogintype(4)" v-if="logintype!=4">Register</a>
									    </div>
									    <div class="col-md-12 mt-20" v-if="false">
									    	<button @click="modify()"  type="button"  class="btn btn-block btn-outline-primary extr_textbox4">
									    	<i class="fa fa-mobile"></i>
									    	Forget Password
									    	</button>
									    </div>
								    </div>
							    </div>
							    
							</div>
						</div>
				</div>
   		</div>
    
     <div v-if="logintype==4">
    	<register></register>

    </div>
    <div v-if="logintype==5">
        <forgot-password-modal></forgot-password-modal>
    </div>
  </div>
</template>

<script>

import Register from '../components/login/Register'
import ForgotPassword from '../components/login/ForgotPassword'

import PhonePasswordLogin from '../components/login/PhonePasswordLogin.vue';
import EmailPasswordLogin from '../components/login/EmailPasswordLogin.vue';
import ForgotPasswordModal from '../components/login/ForgotPasswordModal.vue';
import PhoneOtpLogin from '../components/login/PhoneOTPLogin.vue';
  export default {
	  name:'login',
	  components: {
		  Register,ForgotPassword,PhonePasswordLogin,EmailPasswordLogin,ForgotPasswordModal,PhoneOtpLogin
	  },
    data(){
      return{
        phNo: '',
        email:'',
        appVerifier : '',
        otp : '',
        verifyotpdiv:false,
        resenddiv:false,
        logintogglestring:"Login with Password",
        otplogin:true,
        password:'',
        message:'',
        loginpop:1,
        displayError:false,
        loginstus:0,
        buttonstatus:1
      }
    },
    computed:{
    	logintype(){
    		return this.$store.state.viewno;
    	}
    },
    mounted(){
    	this.$store.commit('assignviewno',3)
		this.$store.commit('assignloadingstatus',0)
    },
    methods :{
    
    	modify(){
			this.$modal.show("forgetpassword");
		},
		hidescreen(){
			this.loginstus=1;
			this.buttonstatus=0;
			this.$store.commit('assignviewno',5)
			
		},
		cancel(){
			this.$store.commit('assignviewno',3)
			this.buttonstatus=1;
			this.loginstus=0;
		},
    	
    	changelogintype(type1){
    		this.loginpop = 1;
    		if(type1 == 4){
    			this.loginpop = 0;
    		}
    		this.$store.commit('assignviewno',type1)
    		
    	},
    	
    	logintoggle(){
    		if(this.otplogin){
    			this.otplogin = false;
    			this.logintogglestring = "Login with OTP";
    		} else {
    			this.otplogin = true;
    			this.logintogglestring = "Login with Password";
    		}
    	},
      
      	processResponse(data){
            console.log(data)
            if(data.success){
                console.log(data.access_token)
                localStorage.setItem('token', data.access_token); 
                this.$router.push({ path:'/'})
				this.$store.commit('assignloadingstatus',0)
                this.$http.interceptors.request.use(
					(config) => {
						const token = localStorage.getItem('token');
						if (token) {
							config.headers['Authorization'] = `Bearer ${token}`;
						}
						return config;
					},
					(error) => {
						return Promise.reject(error);
					}
				);
				setTimeout(() => {
					window.location.reload()
				}, 1000);
				this.$notify({text:'login Successfully',type:'Success'})
				
            } else {
				this.displayError = true;
                this.message = "Email/Password Not Correct"
				this.$notify({text:'Invalid Credentitals',type:'error'})
				this.$store.commit('assignloadingstatus',0)
            }
      	},
      
    
    
  }
}

</script>
